import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function TechnologiesUsed(props) {
  // apply centering on project pages
  const location = useLocation();

  const [applyCenterClass, setApplyCenterClass] = useState(false);

  useEffect(() => {
    setApplyCenterClass(location.pathname.includes("ui" || "web"));
  }, [location.pathname]);

  const classCentered = {
    justifyContent: "center",
  };
  return (
    <div className="technologyContainer">
      <h3
        style={{
          marginBottom: "1rem",
        }}
      >
        Technologies used
      </h3>
      <div
        className="projectTechnologies"
        style={applyCenterClass ? classCentered : {}}
      >
        {props.technologies.map((item, i) => {
          return (
            <p className="technologyCategories" key={i}>
              {item}
            </p>
          );
        })}
      </div>
    </div>
  );
}
