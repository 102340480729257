import React from "react";
import AnimatedSection from "../components/AnimatedSections";
import { Link } from "react-router-dom";
import GTM from "../components/GTM";
import { HorizontalInfoBox } from "../components/InfoCard";
import googleLogo from "../assets/icons/google-g-logo.svg";
import microsoftLogo from "../assets/icons/microsoft-logo.svg";

// compontent
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import HelmetHeader from "../components/HelmetHeader";

// data
import Data from "../data/workExperience.json";

export default function AboutMe(props) {
  // gtm
  GTM({
    pathname: "/about",
  });

  // data
  const experience = Data.experience;

  /*  // responsive container
  const [wideLayout, setWideLayout] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWideLayout(window.innerWidth > 1500);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); */

  const ExperienceCard = () => {
    return (
      <>
        {experience
          .filter((item) => item.visible === true)
          .map((item) => (
            <VerticalTimelineElement
              key={item.id}
              width={100}
              className="vertical-timeline-element--work"
              contentStyle={{
                color: "#fff",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  var(--almostBlack)",
              }}
              date={item.date}
              iconStyle={{
                backgroundColor: "var(--almostBlack)",
                color: "var(--lightWhite)",
              }}
              icon={
                <div className="imageContainer">
                  <img
                    src={require(`../assets/company-icons/${item.icon}`)}
                    alt={item.company}
                    width={90}
                  ></img>
                </div>
              }
            >
              <h3 className="vertical-timeline-element-title">{item.title}</h3>
              <h4 className="vertical-timeline-element-subtitle">
                {item.company}
              </h4>
              <ul className="vertical-timeline-element-description">
                {item.description.map((point, i) => (
                  <li key={i}>{point}</li>
                ))}
              </ul>
            </VerticalTimelineElement>
          ))}
      </>
    );
  };

  return (
    <div className="extendedContainer">
      <HelmetHeader title="About" />
      <AnimatedSection>
        <h1>About</h1>
      </AnimatedSection>
      <div className="aboutme-section">
        <AnimatedSection delay={0.2}>
          <p>
            I'm Dóra Kelemen, I'm passionate about websites & design. Through
            this website, I aim to provide you with a glimpse into my
            professional journey and the skills I've honed along the way. From
            graphic design to web development, I've had the opportunity to work
            on diverse projects that have shaped my expertise in creating
            visually stunning and user-friendly digital experiences.
          </p>
          <p>
            When I'm not immersed in the world of design and development, I find
            joy in exploring various hobbies. Photography is one of my passions,
            but nature hikes and traveling also inspire me. I also share{" "}
            <Link to="https://www.instagram.com/dori.kelemen/" target="_blank">
              <button className="textButton">
                {" "}
                these stories on instagram
              </button>
            </Link>
            , or feel free to{" "}
            <Link to="/photography">
              <button className="textButton">
                check out some of my highlighted
              </button>
            </Link>{" "}
            photos on this site.
          </p>
        </AnimatedSection>
      </div>

      <AnimatedSection delay={0.4}>
        <HorizontalInfoBox />
      </AnimatedSection>
      <AnimatedSection>
        <VerticalTimeline layout={"1-column-left"}>
          <ExperienceCard />
        </VerticalTimeline>
      </AnimatedSection>
      <AnimatedSection delay={0.3}>
        <Accordion style={{ marginTop: "3rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3 style={{ margin: 0 }}>Studies & Trainings</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <h3 className="qualificationTitles">Schools</h3>
                <div className="qualificationSection">
                  <ul>
                    <li>
                      <h3>Budapest University of Technology and Economics</h3>
                      <span style={{ display: "block" }}>
                        BA degree in Communication & media <br />
                        Visual communication specialisation
                      </span>
                      <span className="qualiDate">Budapest, 2016 - 2020</span>
                    </li>
                    <li>
                      <h3>Andrássy György Catholic Business Academy</h3>
                      <span style={{ display: "block" }}>
                        vocational certificate in marketing, tourism and
                        hospitality studies
                      </span>
                      <span className="qualiDate">Eger, 2011 - 2016</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <h3 className="qualificationTitles">
                  Other courses & trainings
                </h3>
                <div className="qualificationSection">
                  <ul>
                    <li>
                      <div className="flexContainer">
                        <div>
                          <h3>Microsoft Power Platform Developer</h3>
                          <span style={{ display: "block" }}>
                            PL-400 | Microsoft certification
                          </span>
                          <span className="qualiDate">ongoing</span>
                        </div>
                        <img
                          src={microsoftLogo}
                          width="100%"
                          className="serviceIcons"
                          style={{
                            maxWidth: "40px",
                            marginTop: 0,
                          }}
                          alt="microsoft logo"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="flexContainer">
                        <div>
                          <h3>Google UX Design Specialization</h3>
                          <span style={{ display: "block" }}>
                            Coursera - Google
                          </span>
                          <span className="qualiDate">2023</span>
                        </div>
                        <img
                          src={googleLogo}
                          width="100%"
                          className="serviceIcons"
                          style={{
                            maxWidth: "40px",
                            marginTop: 0,
                          }}
                          alt="google g logo"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="qualificationSection">
                  <ul>
                    <li>
                      <h3 style={{ marginTop: "1rem" }}>Tour guide</h3>
                      <span style={{ display: "block" }}>
                        Andrássy György Catholic Business Academy
                      </span>
                      <span className="qualiDate">Eger, 2016</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3 style={{ margin: 0 }}>Language proficiency</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <ul>
                  <li>
                    Hungarian
                    <span style={{ display: "block" }}>native language</span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <ul>
                  <li>
                    English - superlative
                    <span style={{ display: "block" }}>
                      C1 complex certificate
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <ul>
                  <li>
                    German
                    <span style={{ display: "block" }}>basic level</span>
                  </li>
                </ul>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </AnimatedSection>

      <div style={{ marginBottom: "3rem", minHeight: "80px" }}></div>
    </div>
  );
}
