import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Gallery } from "react-grid-gallery";
import { Link } from "react-router-dom";
import lazyload from "lazyload";
import AnimatedSection from "../components/AnimatedSections";
import GTM from "../components/GTM";
import HelmetHeader from "../components/HelmetHeader";

// data
import photoGallery from "../data/photogallery.json";
import projects from "../data/photoProjects.json";
import KeyPhotoHighlights from "../components/KeyPhotoHighlights";

export default function PhotographyPortfolio() {
  // gtm
  GTM({
    pathname: "/photography",
  });

  const allImages = photoGallery.photos;
  const projectimages = projects.photoprojects;

  // lightbox
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const openLightbox = (id) => {
    setSelectedIndex(id);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  // construct
  const constructImg = allImages.map((item) => ({
    id: item.id,
    src: require(`../assets/photography/${item.src}`),
    thumbnail: require(`../assets/photography/${item.src}`),
    thumbnailWidth: item.width,
    thumbnailHeight: item.height,
    caption: item.title,
    location: item.location,
    description: item.description,
    hashkey: item.hashkey,
    width: item.width,
    height: item.height,
  }));

  return (
    <div className="extendedContainer">
      <HelmetHeader title="Photography" />
      <AnimatedSection>
        <h1>Photography portfolio</h1>
      </AnimatedSection>
      <div className="row" style={{ margin: "1.5rem 0" }}>
        <div className="col-lg-6 col-md-6 col-12" style={{ padding: 0 }}>
          <AnimatedSection delay={0.1}>
            <p>
              Welcome to my personal photo gallery, a collection of heartfelt
              moments frozen in time.
            </p>
            <p>
              In this space, I seek to convey the essence of the world around me
              and the beauty found in the simplest moments. I hope these
              snapshots inspire wonder and connection as you explore this
              gallery.
            </p>
            <p>
              Through my camera lens, I've captured the beauty of different
              landscapes and experienced the uniqueness of each destination.
              This gallery serves as a humble compilation of memories and
              reflections from my adventures.
            </p>
          </AnimatedSection>
        </div>
        <div className="col-lg-6 col-md-6 col-12" style={{ padding: 0 }}>
          <AnimatedSection delay={0.2}>
            <div className="skillContainer" style={{ minHeight: "unset" }}>
              <h3 style={{ marginBottom: ".5rem" }}>
                Join me on this visual journey
              </h3>
              <p style={{ margin: 0, padding: 0 }}>
                For more glimpses into my world, find me on Instagram,
                (@dori.kelemen). There, I celebrate the everyday beauty often
                overlooked in our busy lives. Enjoy exploring the images and
                stories captured within. 📸
              </p>

              <Link target="_blank" to="https://www.instagram.com/dori.kelemen">
                <button className="contactButton" style={{ margin: 0 }}>
                  Check out my instgram
                </button>
              </Link>
            </div>
          </AnimatedSection>
        </div>
      </div>
      <AnimatedSection delay={0.3}>
        <div className="filteredItemCollection project-cards-container">
          {projectimages.map((item) => {
            return (
              <KeyPhotoHighlights
                title={item.title}
                subtitle={item.subtitle}
                date={item.date}
                category={item.category}
                url={item.url}
                cardphoto={item.cardphoto}
                folderHandling={item.folderHandling}
                hashkey={item.hashkey}
                description={item.description}
              />
            );
          })}
        </div>
      </AnimatedSection>
      <AnimatedSection>
        <div style={{ margin: "3rem 0" }}>
          <Gallery
            images={constructImg}
            rowHeight={400}
            loading={lazyload}
            onClick={(event, obj) => openLightbox(obj.id)}
          />
          <ModalGateway>
            {lightboxIsOpen && (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={selectedIndex}
                  views={constructImg.map((image) => ({
                    source: image.src,
                  }))}
                />
              </Modal>
            )}
          </ModalGateway>
        </div>
      </AnimatedSection>
    </div>
  );
}
