import { React } from "react";
import ShowOnMobile from "../src/components/ShowOnMobile";

// react components
import { BrowserRouter as Router } from "react-router-dom";

// components
import Menu from "./components/mainMenu";
import { VerticalInfoBox } from "./components/InfoCard";

// styles
import "./styles/global.css";
import "./styles/infocard.css";
import "./styles/mobile.css";

import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  // show mobile
  const { showOnMobile } = ShowOnMobile();

  // clear out cv source when unmounting
  window.addEventListener("beforeunload", function () {
    this.localStorage.removeItem("cv");
    this.localStorage.removeItem("testing");
  });

  return (
    <div className="extended-body">
      <Router>
        {showOnMobile ? "" : <VerticalInfoBox />}
        {/*  {showOnMobile ? "" : <HorizontalInfoBox />} */}
        <Menu />
        <AnimatedRoutes />
      </Router>
    </div>
  );
}

export default App;
