import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// images and icons
import profileImage from "../assets/profileimage_ver2.webp";
import SendIcon from "@mui/icons-material/Send";
import linkedinIco from "../assets/social-icons/linkedin.svg";
import instagramIco from "../assets/social-icons/instagram.svg";
import emailIco from "../assets/social-icons/email.svg";

export const VerticalInfoBox = () => {
  const location = useLocation();
  const [menuStyle, setMenuStyle] = useState("collapsedCard");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const isHomePage =
      location.pathname === "/" || location.pathname === "/contact";
    const newMenuStyle = isHomePage ? "infoCard" : "collapsedCard";

    setMenuStyle(newMenuStyle);
    setLoaded(true);
  }, [location.pathname]);

  return (
    <div className={menuStyle}>
      <div className="firstInformation">
        <div className="profileContainer">
          <img
            src={profileImage}
            width="100%"
            alt="DK profile"
            title="profile"
          />
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div className="moreInformation">
          <h3>Dóra Kelemen</h3>
          <p>Based in</p>
          <p>Budapest, Hungary</p>
        </div>
        <div className="ctaContainer">
          <div className="socialLinks">
            <Link
              to="https://www.linkedin.com/in/dora-kelemen/"
              target="_blank"
            >
              <img src={linkedinIco} width="100%" alt="linkedin icon" />
            </Link>
            <Link to="https://www.instagram.com/dori.kelemen/" target="_blank">
              <img src={instagramIco} width="100%" alt="instagram icon" />
            </Link>
            <Link to="mailto:">
              <img src={emailIco} width="100%" alt="email icon" />
            </Link>
          </div>
          <div className="contactMe">
            <Link to="/contact">
              <button
                className="contactButton"
                style={{
                  width: "100%",
                }}
                variant="contained"
                color="secondary"
              >
                <SendIcon />
                Contact me
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const HorizontalInfoBox = () => {
  return (
    <div className="infoCard cardHorizontal">
      <div className="profileContainer">
        <img src={profileImage} width="100%" alt="DK profile" title="profile" />
      </div>
      <div className="moreInformation">
        <p>Based in</p>
        <p>Budapest, Hungary</p>
      </div>
      <div className="socialLinks">
        <Link to="https://www.linkedin.com/in/dora-kelemen/" target="_blank">
          <img src={linkedinIco} width="100%" alt="linkedin icon" />
        </Link>
        <Link to="https://www.instagram.com/dori.kelemen/" target="_blank">
          <img src={instagramIco} width="100%" alt="instagram icon" />
        </Link>
        <Link to="mailto:">
          <img src={emailIco} width="100%" alt="email icon" />
        </Link>
      </div>
    </div>
  );
};
