import React, { useState, useEffect, useRef } from "react";
import AnimatedSection from "./AnimatedSections";
import Carousel from "./Swiper";
import TechnologiesUsed from "./TechnologiesUsed";
import { Link, useLocation } from "react-router-dom";
import BlurHash from "./BlurHash";
import HelmetHeader from "./HelmetHeader";

//icons
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { styled, keyframes } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// data
import Data from "../data/webProjects.json";

//  Swiper modules and styles
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// base mockup
import macBook from "../assets/macbook-mockup.png";

export default function ProjectPageTemplate(props) {
  const location = useLocation();
  const allProjects = Data.projects;

  const webProject = location.pathname.includes("web");
  const uiProject = location.pathname.includes("ui");

  const projectPageType = webProject ? "Web" : "" || uiProject ? "UI/UX" : "";

  // img construct
  const projectPhoto = require(`../assets/project-items/${props.mainphoto}`);

  // verification states
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [openLock, setOpenLock] = useState(null);

  // toggle
  const toggleHint = () => {
    setShowHint((prevState) => !prevState);
  };

  // check the password
  const checkPassword = () => {
    const passwords = [{ pw: "vodka" }, { pw: "ilovedesign" }];

    // pages that require bypassing
    const requestByPass = allProjects.filter((i) => i.locked).map((i) => i.url);

    // inputs & sources
    const getSource = new URLSearchParams(window.location.search).get("source");
    const answer = document.getElementById("password").value;
    const urlParam = new URLSearchParams(window.location.search).get(
      "password"
    );

    // checking
    const requiresPassword = requestByPass.some((url) =>
      location.pathname.includes(url)
    );

    const findPassword = passwords.find(
      (password) => password.pw.toLowerCase() === answer.toLowerCase()
    );
    const findUrl = passwords.find((password) => password.pw === urlParam);
    const CVCheck = getSource === "cv";
    const testingCheck = getSource === "testing";

    // if the project is locked & requires password
    if (findPassword) {
      setOpenLock(true);
      setTimeout(() => {
        setIsVerified(true);
        setErrorMessage(null);
      }, 800);
    } else {
      setOpenLock(false);
      setIsVerified(false);
      if (answer) {
        setErrorMessage(true);
      } else {
        setErrorMessage(null);
      }
    }

    // if the password is already in the url
    if (findUrl) {
      setOpenLock(true);
      setTimeout(() => {
        setIsVerified(true);
        setErrorMessage(null);
      }, 0);
    }

    // if the user was coming from the CV
    if (CVCheck || testingCheck) {
      setOpenLock(true);
      setTimeout(() => {
        setIsVerified(true);
        setErrorMessage(null);
      }, 0);
    }

    // if the project is not locked & not require pass
    if (!requiresPassword) {
      setIsVerified(true);
      setErrorMessage(null);
    }
  };

  // enforcing unique layout patterns
  const [sideBySide, setSideBySide] = useState(false);

  // check password hook
  useEffect(() => {
    checkPassword();
    setSideBySide(props.layoutchange === true);
  }, [props.layoutchange]);

  //  lock animation
  const lockAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }
  25% {
    transform: rotate(-45deg) scale(1.2);
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
  }
  50% {
    transform: rotate(45deg) scale(1.4);
    filter: drop-shadow(0 -4px 4px rgba(0, 0, 0, 0.3));
  }
  75% {
    transform: rotate(-45deg) scale(1.2);
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
  }
  100% {
    transform: rotate(0deg) scale(1);
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }
`;

  const AnimatedLockIcon = styled(LockIcon)({
    fontSize: "2rem",
    margin: "1rem auto",
  });

  const AnimatedLockOpenIcon = styled(LockOpenIcon)({
    fontSize: "2rem",
    margin: "1rem auto",
    animation: `${lockAnimation} 0.6s ease-in`,
  });

  // custom swiper
  const swiperRef = useRef(null);

  const handlePrevSlideClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextSlideClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  // checking which slide we're on
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  return (
    <>
      {isVerified ? (
        <div className="extendedContainer">
          <HelmetHeader title={`${props.name} | Projects`} />
          <AnimatedSection>
            <Link to="/projects" style={{ width: "fit-content" }}>
              <div className="categoryIndicator">
                Projects |
                <span
                  style={{
                    color: "var(--secondaryAccentColor)",
                    marginLeft: "5px",
                  }}
                >
                  {projectPageType}
                </span>
              </div>
            </Link>
          </AnimatedSection>
          <AnimatedSection>
            <h1 className="showcase--subpages">{props.name}</h1>
            <div className="col-lg-12 col-md-12 col-12 justify-content-center">
              <div className="descriptionContainer">
                <p>{props.description[0]}</p>
                {props.description.slice(1).map((item, i) => {
                  return <p key={i}>{item}</p>;
                })}
              </div>
              <TechnologiesUsed
                sectionTitle="Technologies used"
                technologies={props.technologies}
              />
            </div>
          </AnimatedSection>
          <AnimatedSection>
            {sideBySide ? (
              <>
                <div className="interactiveBlock">
                  <div className="descriptionContainer">
                    <div
                      className="swiper-button-prev"
                      onClick={handlePrevSlideClick}
                    ></div>
                    <h3>{props.images[currentSlideIndex].imageDescription}</h3>
                    <div
                      className="swiper-button-next"
                      onClick={handleNextSlideClick}
                    ></div>
                  </div>

                  <div className="instyleMockup">
                    <Swiper
                      ref={swiperRef}
                      onSlideChange={(swiper) =>
                        setCurrentSlideIndex(swiper.activeIndex)
                      }
                      autoplay={{
                        delay: 150,
                        disableOnInteraction: true,
                      }}
                      spaceBetween={5}
                      style={{
                        marginTop: 0,
                      }}
                      className="macMockup"
                    >
                      {props.images.map((item, i) => {
                        return (
                          <SwiperSlide
                            key={i}
                            className="object--fullLengthScroll"
                          >
                            <img
                              src={require(`../assets/project-items/${item.src}`)}
                              className="object--fullLengthScroll"
                              alt={`${props.name} `}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <img
                      src={macBook}
                      width={100}
                      className="macBook-bgMockup"
                      alt="macBook mockup"
                    />
                  </div>
                </div>
                <div className="keyInfoContainer">
                  <div className="highlightInformation">
                    <p>Date</p>
                    <h4>{props.date}</h4>
                  </div>
                  {props.company === "" ? (
                    ""
                  ) : (
                    <div className="highlightInformation">
                      <p>Company</p>
                      <h4>{props.company}</h4>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="row justify-content-between">
                <div className="col-lg-12 col-md-12 col-12">
                  <AnimatedSection delay={0.5}>
                    <BlurHash
                      src={projectPhoto}
                      alt={`${props.name} mockup`}
                      hash={props.hashkey}
                    ></BlurHash>
                  </AnimatedSection>
                </div>
                <div className="col-lg-12 col-md-12 col-12 justify-content-center">
                  <AnimatedSection>
                    <div className="descriptionContainer">
                      {props.additional_description.map((item, i) => {
                        return <p key={i}>{item}</p>;
                      })}
                      <div className="keyInfoContainer">
                        <div className="highlightInformation">
                          <p>Date</p>
                          <h4>{props.date}</h4>
                        </div>
                        {props.company === "" ? (
                          ""
                        ) : (
                          <div className="highlightInformation">
                            <p>Company</p>
                            <h4>{props.company}</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </AnimatedSection>
                </div>
              </div>
            )}
          </AnimatedSection>
          {sideBySide ? (
            ""
          ) : (
            <AnimatedSection>
              <Carousel
                images={props.images}
                alt={props.name}
                title={props.name}
              />
            </AnimatedSection>
          )}
        </div>
      ) : (
        <div
          style={{
            height: "96vh",
            position: "relative",
          }}
        >
          <div className="passwordLocked">
            {openLock ? (
              <AnimatedLockOpenIcon id="openLock" />
            ) : (
              <AnimatedLockIcon id="openLock" />
            )}
            <h3 style={{ marginBottom: 0 }}>Hello</h3>
            <p>To access this project, please enter the password.</p>
            <form
              onSubmit={(e) => {
                e.preventDefault(); // Prevent the form submission
                checkPassword();
              }}
              className="formSubmit"
            >
              <input
                className={`${errorMessage ? "errorState" : "passwordInput"}`}
                id="password"
                name="password"
                placeholder="enter the provided password"
              ></input>
              <button
                className="contactButton"
                style={{
                  margin: 0,
                }}
              >
                Check
              </button>
            </form>
            <div>
              {errorMessage && (
                <p className="errorFeedback">Incorrect password</p>
              )}
              <div
                className="formSubmit hintAnswer"
                onClick={toggleHint}
                style={{ gap: "5px" }}
              >
                {showHint ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                <p style={{ marginBottom: 0 }}>Need a hint?</p>
              </div>
              <div
                className={`hiddenAnswer ${
                  showHint ? "visibleComp" : "hiddenComp"
                }`}
              >
                <p
                  className={`${showHint ? "visibleComp" : "hiddenComp"}`}
                  style={{
                    fontSize: "14px",
                  }}
                >
                  You can use either the QR code or password in my CV's
                  portfolio section.
                  <br></br>
                  For further questions or problems, please{" "}
                  <Link to="/contact">contact me</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
