import React from "react";
import { motion } from "framer-motion";

const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

/* const AnimatedPage = ({ children }) => {
  return (
    <>
      <motion.div
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{
          opacity: { type: "spring", stiffness: 100 },
          duration: 0.8,
        }}
      >
        {children}
      </motion.div>
    </>
  );
};
 */

const AnimatedPage = ({ children }) => {
  return <>{children}</>;
};

export default AnimatedPage;
