import React, { useState } from "react";
import Spline from "@splinetool/react-spline";

export default function SplineCube() {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleSplineLoad = () => {
    setIsLoaded(true);
  };

  const handleAnimationEnd = () => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  };

  return (
    <div
      className={`spline-fade ${isLoaded ? "loaded" : ""}`}
      onAnimationEnd={handleAnimationEnd}
    >
      <Spline
        seamless
        scene="https://prod.spline.design/1AnB10ODkkfBncOD/scene.splinecode"
        onLoad={handleSplineLoad}
      />
    </div>
  );
}
