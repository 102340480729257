import React from "react";
import { NavLink } from "react-router-dom";
import BlurHash from "./BlurHash";
import LockIcon from "@mui/icons-material/Lock";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AnimatedSection from "./AnimatedSections";

export default function ProjectCards(props) {
  // constructing src
  const imageSource = require(`../assets/project-items/${props.cardphoto}`);

  // show locked
  const locked = props.locked === true;

  return (
    <AnimatedSection transition={{ delay: 0.2, duration: 0.7 }}>
      <div className="outerProjectCard">
        <div className="projectCard">
          {locked && (
            <div className="lockedInd">
              <LockIcon alt="project is locked" width={100} />
            </div>
          )}
          <div className="topCategory">
            <div className="technologyCategories">
              <span>{props.project_type}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-4 col-12">
              <div className="backgroundPhoto">
                <BlurHash
                  src={imageSource}
                  alt={`${props.name} main project photo`}
                  hash={props.hashkey}
                ></BlurHash>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-12">
              <div className="projectDetail">
                <p className="dateInd">{props.date}</p>
                <h3>{props.name}</h3>
                <p>{props.description[0]}</p>
                <div
                  className="projectTechnologies"
                  style={{
                    margin: 0,
                  }}
                >
                  {props.technologies.map((technologies, i) => {
                    return (
                      <span key={i} className="technologyCategories">
                        {technologies}
                      </span>
                    );
                  })}
                </div>
                <NavLink
                  to={
                    props.category === "web"
                      ? `/web/${props.url}`
                      : `/ui/${props.url}`
                  }
                >
                  <button className="chevronButton">
                    <p>Read more</p>
                    <ArrowForwardIcon />
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedSection>
  );
}
