import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MobileCard from "../components/MobileCard";
import ShowOnMobile from "./ShowOnMobile";

// icons
import WindowIcon from "@mui/icons-material/Window";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CameraIcon from "@mui/icons-material/Camera";
import BuildIcon from "@mui/icons-material/Build";
import SendIcon from "@mui/icons-material/Send";
import ApiIcon from "@mui/icons-material/Api";

//mobile specific
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

export default function Menu() {
  // show mobile
  const { showOnMobile } = ShowOnMobile();

  const [visibleArrowBack, setVisibleArrowBack] = useState(false);
  const { pathname } = useLocation();
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState(false);

  useEffect(() => {
    const storedShouldPlayAnimation = localStorage.getItem(
      "shouldPlayAnimation"
    );

    if (storedShouldPlayAnimation) {
      localStorage.removeItem("shouldPlayAnimation");
      setShouldPlayAnimation(false);
    }
  }, []);

  useEffect(() => {
    // const page types
    const isProjectPage =
      pathname.includes("web") ||
      pathname.includes("ui") ||
      pathname.includes("photography/");
    if (isProjectPage) {
      setVisibleArrowBack(true);
    } else {
      setVisibleArrowBack(false);
    }

    window.scrollTo(0, 0); // Scroll to the top of the page on every page load
  }, [pathname]);

  // open mobile menu
  const [showOpenMenu, setShowOpenMenu] = useState(false);

  const toggleMenuOpen = () => {
    setShowOpenMenu((prevState) => !prevState);
    if (showOpenMenu) {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const closeWithOverLay = () => {
    setShowOpenMenu(false);
    document.body.style.overflow = "";
  };

  // go back
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back one step in the navigation history
  };

  return (
    <>
      {showOnMobile ? (
        <>
          <div className="hamburger-menu" onClick={toggleMenuOpen}>
            <span class="mobileMenuIcon">
              {showOpenMenu ? <CloseIcon /> : <MenuIcon />}
            </span>
          </div>

          {showOpenMenu && (
            <div
              className={showOpenMenu ? "openMenuOverlay" : ""}
              onClick={closeWithOverLay}
            ></div>
          )}

          <div
            className={`mobileMenuItemContainer ${
              showOpenMenu ? "openMenu" : ""
            }`}
          >
            <div>
              <li className="link-wrapper menuOptions">
                <div className="menuLabelContainer">
                  <NavLink
                    to="/"
                    activeClassName="active"
                    onClick={() => toggleMenuOpen(false)}
                  >
                    <p className="menuLabels">Home</p>
                    <ApiIcon />
                  </NavLink>
                </div>

                <div className="menuLabelContainer">
                  <NavLink
                    to="/about"
                    activeClassName="active"
                    onClick={() => toggleMenuOpen(false)}
                  >
                    <p className="menuLabels">About</p>

                    <AccountCircleIcon />
                  </NavLink>
                </div>

                <div className="menuLabelContainer">
                  <NavLink
                    to="/skills"
                    activeClassName="active"
                    onClick={() => toggleMenuOpen(false)}
                  >
                    <p className="menuLabels">Skills</p>
                    <BuildIcon />
                  </NavLink>
                </div>

                <div className="menuLabelContainer">
                  <NavLink
                    to="/projects"
                    activeClassName="active"
                    onClick={() => toggleMenuOpen(false)}
                  >
                    <p className="menuLabels">Projects</p>
                    <WindowIcon />
                  </NavLink>
                </div>

                <div className="menuLabelContainer">
                  <NavLink
                    to="/photography"
                    activeClassName="active"
                    onClick={() => toggleMenuOpen(false)}
                  >
                    <p className="menuLabels">Photography</p>
                    <CameraIcon />
                  </NavLink>
                </div>

                <div className="menuLabelContainer">
                  <NavLink
                    to="/contact"
                    activeClassName="active"
                    onClick={() => toggleMenuOpen(false)}
                  >
                    <p className="menuLabels">Contact</p>
                    <SendIcon />
                  </NavLink>
                </div>
              </li>
            </div>
            <div>
              <MobileCard />
            </div>
          </div>
        </>
      ) : (
        <div className="menuItemContainer">
          <li className="link-wrapper menuOptions">
            <div className="menuLabelContainer">
              <p className="menuLabels">Home</p>
              <NavLink to="/" activeClassName="active">
                <ApiIcon />
              </NavLink>
            </div>

            <div className="menuLabelContainer">
              <NavLink to="/about" activeClassName="active">
                <AccountCircleIcon />
              </NavLink>
              <p className="menuLabels">About</p>
            </div>

            <div className="menuLabelContainer">
              <NavLink to="/skills" activeClassName="active">
                <BuildIcon />
              </NavLink>

              <p className="menuLabels">Skills</p>
            </div>

            <div className="menuLabelContainer">
              <NavLink to="/projects" activeClassName="active">
                <WindowIcon />
                <p className="menuLabels">Projects</p>
              </NavLink>
            </div>

            <div className="menuLabelContainer">
              <NavLink to="/photography" activeClassName="active">
                <CameraIcon />
                <p className="menuLabels">Photography</p>
              </NavLink>
            </div>

            <div className="menuLabelContainer">
              <NavLink to="/contact" activeClassName="active">
                <SendIcon />
                <p className="menuLabels">Contact</p>
              </NavLink>
            </div>
          </li>
          <li
            className={`link-wrapper menuOptions goBackIcon ${
              visibleArrowBack ? "extraMenuOptions" : "hiddenExtraOptions"
            }`}
          >
            <div className="menuLabelContainer" onClick={goBack}>
              <ArrowBackIcon id="back" />

              <p className="menuLabels">Back</p>
            </div>
          </li>
        </div>
      )}
    </>
  );
}
