import React, { useEffect, useRef } from "react";
import {
  motion,
  useInView,
  useAnimation,
  AnimatePresence,
} from "framer-motion";
import ShowOnMobile from "./ShowOnMobile";

const AnimatedSection = ({ children, delay }) => {
  // disable motion on mobile
  const { showOnMobile } = ShowOnMobile();

  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  return (
    <>
      {showOnMobile ? (
        <div ref={ref}>{children}</div>
      ) : (
        <AnimatePresence>
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 80 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
              duration: 0.7,
              delay: delay,
              stiffness: "100",
              type: "spring",
            }}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default AnimatedSection;
