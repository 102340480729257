import { useEffect, useState } from "react";

export default function ShowOnMobile() {
  // filter variations
  const [showOnMobile, setShowOnMobile] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setShowOnMobile(window.innerWidth < 932);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return { showOnMobile, setShowOnMobile };
}
