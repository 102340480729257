import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GTM from "../components/GTM";
import HelmetHeader from "../components/HelmetHeader";
import { Form } from "react-bootstrap";

import AnimatedPage from "../components/AnimatedPage";
import AnimatedSection from "../components/AnimatedSections";
import ProjectCards from "../components/ProjectCards";
import PowerPlatformLeadCard from "../components/PowerPlatformLeadCard";
import ComponentCards from "../components/ComponentCards";

// icons and imgs
import CloseIcon from "@mui/icons-material/Close";

// dropdown filter
import { FormControl, MenuItem, Select } from "@mui/material";

// data
import Data from "../data/webProjects.json";
import Components from "../data/webComponents.json";
import NoIndex from "../components/NoIndex";

export default function Projects() {
  // gtm
  GTM({
    pathname: "/projects",
  });

  // checking source origin
  const isCVSource = localStorage.getItem("cv");
  const [showCVMessage, setCVMessage] = useState(false);

  useEffect(() => {
    setCVMessage(!!isCVSource);
  }, [isCVSource]);

  // projects filtering
  const allProjects = Data.projects;
  const allComponents = Components.components;

  const [projectCategory, setProjectCategory] = useState(null);
  const [projectType, setProjectType] = useState("All");
  const [showNoResults, setShowNoResults] = useState(null);

  // filter
  const filterProjectsByType = (type, projects, components) => {
    if (type === "All") {
      return [...projects, ...components];
    } else if (type === "Personal") {
      return projects
        .filter((project) => project.company === "")
        .concat(components.filter((component) => component.company === ""));
    } else if (type === "Work") {
      return projects
        .filter((project) => project.company !== "")
        .concat(components.filter((component) => component.company !== ""));
    }
  };

  // filtering project types
  const testingPage = window.location.href.includes("testing"); // hide if cv param is missing
  const filteredProjects = allProjects
    .filter((item) => {
      return (
        (projectCategory === null || projectCategory === item.project_type) &&
        (testingPage
          ? item.hidden || !item.hidden
          : item.hidden === false || item.hidden === null) &&
        filterProjectsByType(projectType, [item], []).length > 0
      );
    })
    .sort((a, b) => {
      const older = parseInt(a.date + a.id);
      const newer = parseInt(b.date + b.id);
      return newer - older;
    });

  // filtering component types
  const filteredComponents = allComponents
    .filter((item) => {
      return (
        (projectCategory === null || projectCategory === item.project_type) &&
        filterProjectsByType(projectType, [], [item]).length > 0
      );
    })
    .sort((a, b) => {
      const older = parseInt(a.date);
      const newer = parseInt(b.date);
      return newer - older;
    });

  // toggling the radio buttons
  const toggleProjects = (value) => {
    // checking if previous values match
    const previousRadioFilterValue = localStorage.getItem("projectCategory");

    if (value === null || value !== previousRadioFilterValue) {
      localStorage.removeItem("projectCategory");
    }

    if (value !== null) {
      localStorage.setItem("projectCategory", value);
    }

    setProjectCategory(value);
  };

  // sorting the power platform card
  const [showPowerPlatform, setShowPowerPlatform] = useState(null);

  useEffect(() => {
    if (projectType !== "Personal" && projectCategory === "app") {
      setShowPowerPlatform(true);
    } else if (projectType !== "Personal" && projectCategory === null) {
      setShowPowerPlatform(true);
    } else if (projectCategory === "app" && projectType === null) {
      setShowPowerPlatform(true);
    } else if (projectCategory === "all" && projectType === null) {
      setShowPowerPlatform(true);
    } else {
      setShowPowerPlatform(false);
    }
  }, [projectCategory, projectType]);

  // Filter callback
  useEffect(() => {
    // saving filters
    const storedRadioFilter = localStorage.getItem("projectCategory");
    if (storedRadioFilter) {
      setProjectCategory(storedRadioFilter);
    }
    const storedDropDownFilter = localStorage.getItem("projectType");
    if (storedDropDownFilter) {
      setProjectType(storedDropDownFilter);
    }
  }, []);

  // using dropdown filters
  const switchPersonalProject = (e) => {
    setProjectType(e.target.value);
    localStorage.setItem("projectType", e.target.value);
  };
  // clearing storage on site leave
  window.addEventListener("beforeunload", function (event) {
    localStorage.clear();
  });

  // Check if there are no results
  useEffect(() => {
    setShowNoResults(filteredProjects.length === 0 && !showPowerPlatform);
  }, [filteredProjects, showPowerPlatform]);

  return (
    <AnimatedPage>
      <NoIndex />
      <HelmetHeader title="Projects" />
      <div className="extendedContainer">
        <AnimatedSection>
          <h1>Projects</h1>
        </AnimatedSection>

        {/* testing info */}
        {testingPage && (
          <p style={{ color: "red" }}>Testing mode active</p>

        )}

        {/* recruiter info */}
        {isCVSource && showCVMessage && (
          <AnimatedSection delay={0.2}>
            <div className="recruiterInformation">
              <CloseIcon
                onClick={() => setCVMessage(false)}
                className="closeDownMenu"
              />
              <h3>Hi there,</h3>
              <p>
                You're currently exploring my portfolio via a customized
                CV-linked URL, granting you automatic access to my highlighted
                projects from previous roles.
              </p>
              <p>
                You can always return to my CV for the password. If you have any
                questions or need additional information, please feel free to{" "}
                <Link to="/contact"> reach out</Link>.
              </p>
            </div>
          </AnimatedSection>
        )}

        {/* filter variations */}
        <AnimatedSection delay={0.2}>
          <div className="row justify-content-between">
            <div className="col-lg-9 col-md-8">
              <div className="filterContainer">
                <div>
                  <p>Select a category:</p>
                </div>
                <Form className="projectSelector">
                  {["radio"].map((type) => (
                    <>
                      <Form.Check
                        key={`${type}`}
                        label="Show all"
                        name="Show all"
                        type={type}
                        checked={projectCategory === null}
                        id={`${type}-1`}
                        onChange={() => toggleProjects(null)}
                      />
                      <Form.Check
                        label="Apps"
                        name="Apps"
                        type={type}
                        checked={projectCategory === "app"}
                        id={`${type}-2`}
                        onChange={() => toggleProjects("app")}
                      />
                      <Form.Check
                        label="Mini sites"
                        name="Minisites"
                        type={type}
                        checked={projectCategory === "mini site"}
                        id={`${type}-4`}
                        onChange={() => toggleProjects("mini site")}
                      />
                      <Form.Check
                        label="Landing pages"
                        name="Landing"
                        type={type}
                        checked={projectCategory === "landing page"}
                        id={`${type}-5`}
                        onChange={() => toggleProjects("landing page")}
                      />
                      <Form.Check
                        label="Prototypes"
                        name="Prototypes"
                        checked={projectCategory === "prototype"}
                        type={type}
                        id={`${type}-6`}
                        onChange={() => toggleProjects("prototype")}
                      />
                    </>
                  ))}
                </Form>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="filterContainer allProjectselector">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={projectType}
                    onChange={switchPersonalProject}
                    displayEmpty
                    className="customFormControl"
                  >
                    <MenuItem value="All">All types</MenuItem>
                    <MenuItem value="Personal">Personal</MenuItem>
                    <MenuItem value="Work">Work</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </AnimatedSection>
        {showNoResults && (
          <p>This query returned no items, please adjust the filters.</p>
        )}
        <AnimatedSection delay={0.3}>
          <div className="project-cards-container filteredItemCollection">
            {filteredProjects.map((item) => {
              return (
                <ProjectCards
                  name={item.name}
                  description={item.description}
                  date={item.date}
                  hashkey={item.hashkey}
                  url={item.url}
                  category={item.category}
                  project_type={item.project_type}
                  technologies={item.technologies}
                  mainphoto={item.mainphoto}
                  cardphoto={item.cardphoto}
                  locked={isCVSource || testingPage ? "" : item.locked}
                />
              );
            })}
            {showPowerPlatform && (
              <PowerPlatformLeadCard locked={!isCVSource ? true : false} />
            )}
            <div className="row" style={{ marginBottom: "4rem" }}>
              {filteredComponents.map((item) => {
                return (
                  <ComponentCards
                    id={item.id}
                    name={item.name}
                    category={item.category}
                    company={item.company}
                    mockup={item.mockup}
                    mockupVariant={item.mockupVariant}
                    date={item.date}
                    cardphoto={item.cardphoto}
                    mainphoto={item.mainphoto}
                    pageurl={item.pageurl}
                    url={item.url}
                    technologies={item.technologies}
                    description={item.description}
                  />
                );
              })}
            </div>
          </div>
        </AnimatedSection>
      </div>
    </AnimatedPage>
  );
}
