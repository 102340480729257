import React, { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";

export default function BlurHash({ src, hash }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = src;
  }, [src, hash]);

  return (
    <>
      <div style={{ display: imageLoaded ? "none" : "inline" }}>
        <Blurhash
          hash="L23IuBj=rSf9Vrj[o#a|m~ayXXkC"
          height={1000}
          width={1000}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
      <img
        onLoad={() => setImageLoaded(true)}
        src={src}
        alt=""
        style={{ display: imageLoaded ? "inline" : "none" }}
        className="mainPhoto"
      ></img>
    </>
  );
}
