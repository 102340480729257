import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export default function GTM({ pathname }) {
  // not to push testing and localhost to analytics
  const [devMode, setDevmode] = useState(null);

  const urlParam = new URLSearchParams(window.location.search).get("source");

  useEffect(() => {
    const checkParam = () => {
      if (
        urlParam === "testing" ||
        window.location.href.includes("localhost")
      ) {
        setDevmode(true);
      } else {
        setDevmode(false);
      }
    };

    checkParam();
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
    }
    if (window.dataLayer.length > 150) {
      window.dataLayer = [];
    }
  });

  /* exclude when using devmode */
  {
    !devMode &&
      window.dataLayer.push(
        {
          event: "pageview",
          path: pathname,
        },
        [pathname]
      );
  }

  return (
    <>
      {!devMode && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-WGEWCDHXZD"
          ></script>
        </Helmet>
      )}
    </>
  );
}
