import React from "react";
import Data from "../../data/photoProjects.json";
import PhototPageTemplate from "../../components/PhotoPageTemplate";

export default function RekaAndDani() {
  const pageData = Data.photoprojects;

  const photographyPage = pageData.map((item) => {
    return (
      <PhototPageTemplate
        id={item.id}
        title={item.title}
        subtitle={item.subtitle}
        url={item.url}
        folderHandling={item.folderHandling}
        mainPhoto={item.mainPhoto}
        date={item.date}
        description={item.description}
        additional_description={item.additional_description}
        images={item.images}
        cardphoto={item.cardphoto}
      />
    );
  });
  return photographyPage[2];
}
