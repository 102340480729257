import React from "react";
import AnimatedSection from "./AnimatedSections";
import Header from "./HelmetHeader";

// icons
import CodeIcon from "@mui/icons-material/Code";
import GridViewIcon from "@mui/icons-material/GridView";
import SettingsSystemDaydreamIcon from "@mui/icons-material/SettingsSystemDaydream";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";

export default function MySkills() {
  const skillTree = [
    {
      id: 0,
      title: "UX/UI Design",
      icon: <VerticalSplitIcon />,
      description:
        "Focused on creating intuitive, engaging interfaces with a focus on user experience.",
    },
    {
      id: 1,
      title: "Frontend Development",
      icon: <CodeIcon />,
      description:
        "Skilled in building modern, responsive web interfaces for seamless user interaction.",
    },
    {
      id: 2,
      title: "Microsoft Power Platform",
      icon: <SettingsSystemDaydreamIcon />,
      description:
        "Proficient in utilizing the Power Platform ecosystem tailored to business needs.",
    },
  ];

  return (
    <div className="row align-items-start">
      {skillTree.map((item, i) => {
        return (
          <div className="col-lg-4 col-md-6 col-12" key={i}>
            <AnimatedSection delay={i * 0.3}>
              <div className="designContainer">
                {item.icon}
                <div>
                  <h3 style={i === 0 ? { width: "50%" } : {}}>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            </AnimatedSection>
          </div>
        );
      })}
    </div>
  );
}
