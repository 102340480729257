import React from "react";
import GTM from "../components/GTM";
import MySkills from "../components/MySkills";
import AnimatedSection from "../components/AnimatedSections";

export default function HomePage() {
  // gtm
  GTM({
    pathname: "/",
  });

  return (
    <div className="maxContainer">
      <AnimatedSection delay={0.2}>
        <div className="firstInformation">
          <h1 className="introText" style={{ color: "#B4E7CE" }}>
            Hey, I'm Dóra, I build web stuff.
          </h1>
        </div>
      </AnimatedSection>
      <AnimatedSection delay={0.4}>
        <p className="introText">
          I am a dedicated and passionate web designer, specialist & power
          platform user, skilled in crafting captivating online experiences.
        </p>
      </AnimatedSection>
      <div className="myWork">
        <MySkills />
      </div>
    </div>
  );
}
