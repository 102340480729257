import React from "react";
import { Link } from "react-router-dom";

// icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function MobileCard() {
  return (
    <div className="mobileCard">
      <h3>Get in touch</h3>
      <div className="buttonContainer">
        <MailOutlineIcon />
        <p>dorikelemen@icloud.com</p>
      </div>
      <Link to="https://www.linkedin.com/in/dora-kelemen/" target="_blank">
        <div className="buttonContainer">
          <LinkedInIcon />
          <p>/dora-kelemen</p>
        </div>
      </Link>
      <Link to="https://www.instagram.com/dori.kelemen/" target="_blank">
        <div className="buttonContainer">
          <InstagramIcon />
          <p>/dori.kelemen</p>
        </div>
      </Link>
    </div>
  );
}
